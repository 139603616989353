body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.backgroundBanner {
  background-image: url("../public/img/backgroundBanner.jpeg");
  min-width: 100%;
  height: 60vh;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.center {
  text-align: center;
}